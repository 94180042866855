const decode = (jwt) => {
  if (typeof jwt !== 'string') throw new TypeError('Invalid jwt type string')

  const jjj = jwt.split('.')

  if (jjj.length !== 3) throw new TypeError('Malformat jwt')

  return JSON.parse(atob(jjj[1]))
}

export default {
  decode
}
