<template>
  <section class="hero is-large">
    <div class="hero-body">
      <div class="container is-desktop has-text-centered">
        <div class="auth--loader">
          <PpSpiner/>
        </div>
        <p>{{ message }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import cookies from 'js-cookie'
import lv2, { axiosErrorHandler } from '../../lv2Api'
import store from '../../store'

const postAuth = async ({ body }) => {
  try {
    const { data } = await lv2.post(`/customer/auth`, body)
    const { outlets, meta } = data
    store.setMetaAction(meta)
    return outlets
  } catch (err) {
    const data = axiosErrorHandler(err)
    if (data.meta) store.setMetaAction(data.meta)
    if (data.error && data.error.type === 'invalid_request_error') throw data.error
    if (data.error && data.error.type === 'authentication_error') throw data.error
    store.setGloablModal({ show: true, content: data.message || data.error.message })
    throw err
  }
}
export default {
  name: 'CustomerAuth',
  props: {
    query: Object,
    provider: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      message: 'Please wait',
      reff: undefined
    }
  },
  metaInfo () {
    return {
      title: `Auth: ${this.provider}`
    }
  },
  created () {
    if (this.provider === 'facebook') {
      // Verify any crosssite attack
      const splited = this.query.state && this.query.state.split('%-%-%')
      if (`${splited[0]}` !== cookies.get('ssxx')) {
        this.message = 'Malicious attempt. Login fail.'
        return setTimeout(() => {
          this.redirectOrHome()
        }, 2000)
      }
      // incase the rerr is not base64
      try {
        this.reff = atob(splited[1])
      } catch (err) {
        console.error(err)
      }
      // If error ouccred, user cancel or deined permission
      if (this.query.error != null) {
        this.message = this.query.error_description
        return setTimeout(() => {
          this.redirectOrHome()
        }, 2000)
      }
    }
    //     YOUR_REDIRECT_URI?
    //  error_reason=user_denied
    //  &error=access_denied
    //  &error_description=Permissions+error
    if (this.query.code != null) {
      // continue login flow
      const body = {
        code: this.query.code,
        provider: this.provider
      }
      postAuth({ body })
        .then(() => {
          this.message = 'success!'
          setTimeout(() => {
            this.redirectOrHome()
          }, 1500)
        })
        .catch((err) => {
          console.error(err)
          this.message = 'Fail'
          this.redirectOrHome()
        })
    }
  },
  methods: {
    redirectOrHome () {
      if (this.reff != null) {
        this.$router.replace({ path: this.reff })
      } else {
        this.$router.replace({ name: 'home' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';
  .auth--loader {
    padding: 100px 40px 40px;
  }
  .hero-body {
    min-height: 450px;
  }
</style>
