<template>
  <section class="section is-mini">
    <div class="container is-desktop">
      <PpGap />
      <div
        v-if="lv2FacebookLoginEnabled"
        class="login-form--social">
      <ContinueWithFacebook
        class="is-inline-block"
        :reff="query.reff" />
      <ZuDivider />
      </div>
      <h3 class="title is-marginless is-5 has-text-centered">Email login</h3>
      <PpGap />
      <div class="shadow-box form-content login-form--email-login">
        <el-form
          label-position="top"
          :model="localForm"
          ref="localForm"
          @submit.native="doLogin"
          class="">
          <el-form-item
            label="Email"
            prop="email"
            :rules="[
              { type: 'email', message: 'Invalid email format', trigger: [ 'change', 'blur', 'input' ] },
              { required: true, message: 'Email is required', trigger: [ 'change', 'blur' ] }
            ]">
            <el-input
              v-model.trim="localForm.email"
              name="loginFormEmail"
              type="email"
              autocomplete="email"
              placeholder="E.g: yourname@gmail.com" />
          </el-form-item>
          <el-form-item
            label="Password"
            prop="password"
            :rules="[
              { required: true, message: 'Password is required', trigger: [ 'change', 'blur' ] }
            ]">
            <el-input v-model.trim="localForm.password"
            type="password"
            placeholder="xxxxxxxxx" />
          </el-form-item>
        <div class="list is-narrow is-gapless">
          <div class="list-content">
            <router-link
              class="el-button el-button--text"
              :to="{ name: 'forgotPassword', query: $route.query }">
              Forgot password?
            </router-link>
          </div>
          <div class="list-action">
            <router-link
              class="el-button el-button--text"
              :to="{ name: 'signup', query: $route.query }">
              Signup
            </router-link>
            <el-button
              native-type="submit"
              :loading="isLoading"
              type="success">
              Login
            </el-button>
          </div>
        </div>
        </el-form>
      </div>
    </div>
    <PpModal
      v-model="submitModalVisibility"
      :isLoading="isLoading"
      loadingMessage="Requesting">
      <div class="box" style="padding: 0;">
        <div class="box-content"
          v-if="loginError.message">
          <h5 class="is-size-5 has-text-weight-semibold has-text-black-ter">
            <i class="el-icon-warning has-text-danger"/> Login error
          </h5>
          <div class="list is-gapless">
            <div class="list-content">
              {{ loginError.message }}
            </div>
          </div>
          <div class="gap-x2"></div>
          <div class="list is-gapless is-bottomless">
            <div class="list-content"></div>
            <div
              class="list-action">
              <el-button
                type="primary"
                @click="submitModalVisibility = false">
                Ok
              </el-button>
            </div>
          </div>
        </div>
        <div class="box-content has-text-centered"
          v-else>
          <i class="el-icon-success has-text-success is-size-1" />
          <div class="gap-x2"></div>
          <h5 class="is-size-5 has-text-weight-semibold has-text-black-ter">
            Login success
          </h5>
          <div class="gap-x2"></div>
        </div>
      </div>
    </PpModal>
    <PpGap size="large" />
  </section>
</template>

<script>
import lv2, { axiosErrorHandler } from '../../lv2Api'
import store from '../../store'
import ContinueWithFacebook from '../../components/ContinueWithFacebook'

const postCustomerLogin = async ({ body }) => {
  try {
    const { data } = await lv2.post(`/customer/login`, body)
    const { outlets, meta } = data
    store.setMetaAction(meta)
    return outlets
  } catch (err) {
    const data = axiosErrorHandler(err)
    if (data.meta) store.setMetaAction(data.meta)
    if (data.error && data.error.type === 'invalid_request_error') throw data.error
    if (data.error && data.error.type === 'authentication_error') throw data.error
    store.setGloablModal({ show: true, content: data.message || data.error.message })
    throw err
  }
}

export default {
  name: 'CustomerLogin',
  components: {
    [ContinueWithFacebook.name]: ContinueWithFacebook
  },
  props: {
    params: Object,
    query: Object
  },
  data () {
    return {
      submitModalVisibility: false,
      isLoading: false,
      loginError: {
      },
      localForm: {
        // email: 'youncxaazzuac@jou.com',
        // password: '123456'
        email: undefined,
        password: undefined
      }
    }
  },
  metaInfo: {
    title: 'Login'
  },
  methods: {
    doLogin (e) {
      e.preventDefault()
      this.$refs.localForm.validate((valid) => {
        if (valid) {
          this.submitModalVisibility = true
          this.isLoading = true
          postCustomerLogin({ body: this.localForm })
            .then(({ cusToken }) => {
              if (cusToken == null) throw new Error('Login success but not receiving cusToken')
              // If url has redirect hints
              if (this.query.reff) {
                this.isLoading = false
                return setTimeout(() => {
                  // incase the rerr is not base64
                  try {
                    return this.$router.replace({ path: atob(this.query.reff) })
                  } catch (err) {
                    console.error(err)
                    return this.$router.replace({ name: 'home' })
                  }
                }, 2000)
              }
              this.$router.replace({ name: 'home' })
            })
            .catch((err) => {
              if (err.code === 'unauthorized') this.loginError.message = 'Email and password not matched'
              this.isLoading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';
  .login-form--social {
    padding-top: $bleed * 3;
    text-align: center;
    & /deep/ .login--fb-button {
      max-width: 280px;
    }
  }
  .login-form--email-login {
    box-shadow: $real-shadow;
  }
  .login-form--social,
  .login-form--email-login,
  .login-from--social {
    margin: auto;
    @include tablet {
      width: 350px;
    }
  }
</style>
