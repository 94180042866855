<template>
  <a :href="loginWithFacebook">
    <img class="login--fb-button is-block" src="@/assets/continue-with-fb.png" />
  </a>
</template>

<script>
import cookies from 'js-cookie'

export default {
  name: 'ContinueWithFacebook',
  props: {
    reff: {
      type: String,
      required: true,
      default: ''
    }
  },
  data () {
    return {
      enabled: process.env.VUE_APP_FB_LOGIN_ENABLE
    }
  },
  computed: {
    loginWithFacebook () {
      // Simply generate a ranndom string we will verify this later
      const k = btoa(Math.pow(Date.now() * Math.random(), 2))
      cookies.set('ssxx', k, { expires: 7 })
      return `https://www.facebook.com/v3.2/dialog/oauth?client_id=${process.env.VUE_APP_FB_CLIENT_ID}&auth_type=rerequest&request_type=code&redirect_uri=${location.origin}${process.env.VUE_APP_FB_REDIRECT_URI}&scope=email&state=${k}%-%-%${this.reff}`
    }
  }
}
</script>

<style>

</style>
