<template>
  <section class="section">
    <div class="container is-desktop">
      <h3 class="title is-marginless is-5 has-text-centered">Reset password</h3>
      <PpGap />
      <div class="shadow-box form-content login-form--email-login">
        <el-form
          label-position="top"
          :model="localForm"
          ref="localForm"
          @submit.native="doSubmit"
          class="">
          <el-form-item
            label="Email"
            prop="email"
            :rules="[
              { type: 'email', message: 'Invalid email format', trigger: [ 'change', 'blur', 'input' ] },
              { required: true, message: 'Email is required', trigger: [ 'change', 'blur' ] }
            ]">
            <el-input
              v-model.trim="localForm.email"
              name="loginFormEmail"
              type="email"
              autocomplete="email"
              placeholder="E.g: yourname@gmail.com" />
          </el-form-item>
        <div class="list is-narrow is-gapless">
          <div class="list-content">
          </div>
          <div class="list-action">
            <el-button
              native-type="submit"
              :loading="isLoading"
              type="success">
              Reset
            </el-button>
          </div>
        </div>
        </el-form>
      </div>
    </div>
    <PpModal
      v-model="submitModalVisibility"
      :isLoading="isLoading"
      loadingMessage="Requesting">
      <div class="box" style="padding: 0;">
        <div class="box-content"
          v-if="loginError.message">
          <h5 class="is-size-5 has-text-weight-semibold has-text-black-ter">
            <i class="el-icon-warning has-text-danger"/> Reset password error
          </h5>
          <div class="list is-gapless">
            <div class="list-content">
              {{ loginError.message }}
            </div>
          </div>
          <div class="gap-x2"></div>
          <div class="list is-gapless is-bottomless">
            <div class="list-content"></div>
            <div
              class="list-action">
              <el-button
                type="primary"
                @click="submitModalVisibility = false">
                Ok
              </el-button>
            </div>
          </div>
        </div>
        <div class="box-content has-text-centered"
          v-else>
          <PpGap size="large" />
          <i class="el-icon-success has-text-success is-size-1" />
          <div class="gap-x2"></div>
          <h5 class="is-size-5 has-text-weight-semibold has-text-black-ter">
            Reset password instructions has been sent to your email.<br> <small class="has-text-grey">Please continue from there.</small> <br> <span class="is-size-7">(valid for 15mins) </span>
          </h5>
          <PpGap size="large" />
          <div class="list">
            <div class="list-content"></div>
            <div class="list-action">
              <router-link
                class="el-button el-button--primary"
                :to="{ name: 'login', query: $route.query }">
                Back to login
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </PpModal>
    <PpGap size="large" />
  </section>
</template>

<script>
import lv2, { axiosErrorHandler } from '../../lv2Api'
import store from '../../store'

const postResetPassword = async ({ body }) => {
  try {
    const { data } = await lv2.post(`/customer/forgot-password`, body)
    const { outlets, meta } = data
    store.setMetaAction(meta)
    return outlets
  } catch (err) {
    const data = axiosErrorHandler(err)
    if (data.meta) store.setMetaAction(data.meta)
    if (data.error && data.error.type === 'invalid_request_error') throw data.error
    if (data.error && data.error.type === 'authentication_error') throw data.error
    store.setGloablModal({ show: true, content: data.message || data.error.message })
    throw err
  }
}

export default {
  name: 'CustomerForgotPassword',
  props: {
    params: Object,
    query: Object
  },
  data () {
    return {
      submitModalVisibility: false,
      isLoading: false,
      loginError: {
      },
      localForm: {
        // email: 'youncxaazzuac@jou.com',
        // password: '123456'
        email: undefined
      }
    }
  },
  metaInfo: {
    title: 'Forgot password'
  },
  methods: {
    doSubmit (e) {
      e.preventDefault()
      this.$refs.localForm.validate((valid) => {
        if (valid) {
          this.submitModalVisibility = true
          this.isLoading = true
          postResetPassword({ body: this.localForm })
            .then(() => {
              this.isLoading = false
            })
            .catch((err) => {
              this.loginError.message = err.message
              this.isLoading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';
  .login-form--email-login {
    box-shadow: $real-shadow;
  }
  .login-form--email-login {
    margin: auto;
    @include tablet {
      width: 350px;
    }
  }
</style>
